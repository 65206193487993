export enum LogLevel {
  Fatal,
  Error,
  Warning,
  Information,
  Debug,
  Verbose,
}

export enum DiscountType {
  Percentage = "PERCENTAGE",
  Absolute = "ABSOLUTE",
}

export enum Steps {
  NONE,
  PERSONAL_QUESTIONS,
  MACHINE_INFORMATION,
  DESCRIPTION_AND_UPLOAD,
  SELECT_REPAIR,
}
