export function useGrayLog() {
  async function WriteMessageToGraylog(
    message: string,
    email?: string,
    loglevel: LogLevel = LogLevel.Error,
  ) {
    const source = "Shop";
    const callerFunction = window?.location?.href || "Unknown source";
    const device = navigator?.userAgent || "Unknown device";
    const msg = message || "No message provided";
    const mail = email || "No email provided";
    const logLevel = loglevel;

    const ipResponse = await fetch("https://api.ipify.org?format=json");
    const ipData = await ipResponse.json();
    const ip = ipData.ip || "Unknown IP";

    try {
      await $fetch(`/api/graylog/writeMessageToGraylog`, {
        method: "POST",
        body: {
          source: source,
          message: msg,
          deviceName: device,
          callerFunction: callerFunction,
          clientIp: ip,
          parameters: [
            {
              key: "IP",
              value: ip,
            },
            {
              key: "E-Mail",
              value: mail,
            },
          ],
          logLevel: logLevel,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (e) {
      console.error(e);
    }
  }

  return {
    WriteMessageToGraylog,
  };
}
